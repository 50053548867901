import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import { gsap } from 'gsap'
import * as dat from 'lil-gui'


/**
 * Loaders
 */
const loadingBarWrapper = document.querySelector('.loading-wrap')
const loadingBarElement = document.querySelector('.loading-bar')
const contentText = document.querySelector('p')
const contentBtns = document.querySelectorAll('button')

let sceneReady = false
const loadingManager = new THREE.LoadingManager(
    // Loaded
    () =>
    {
        // Wait a little
        window.setTimeout(() =>
        {
            // Animate overlay
            gsap.to(overlayMaterial.uniforms.uAlpha, { duration: 2, value: 0})
            gsap.from(contentText, {autoAlpha: 0, y: 30, duration: 1})
            gsap.from(contentBtns, {autoAlpha: 0, y: 30,stagger: 0.5, duration: 1})


            // Update loadingBarElement
            loadingBarWrapper.classList.add('ended')
            loadingBarElement.classList.add('ended')
            loadingBarElement.style.transform = ''
        }, 500)

        window.setTimeout(() =>
        {
            sceneReady = true
        }, 2000)
    },

    // Progress
    (itemUrl, itemsLoaded, itemsTotal) =>
    {
        // Calculate the progress and update the loadingBarElement
        const progressRatio = itemsLoaded / itemsTotal
        loadingBarElement.style.transform = `scaleX(${progressRatio})`
    }
)
const gltfLoader = new GLTFLoader(loadingManager)
const cubeTextureLoader = new THREE.CubeTextureLoader(loadingManager)

/**
 * Base
 */

// Debug
const gui = new dat.GUI()
gui.hide()
const debugObject = {}

// Canvas
const canvas = document.querySelector('canvas.webgl')

// Scene
const scene = new THREE.Scene()

/**
 * Overlay
 */
const overlayGeometry = new THREE.PlaneGeometry(2, 2, 1, 1)
const overlayMaterial = new THREE.ShaderMaterial({
    // wireframe: true,
    transparent: true,
    uniforms:
    {
        uAlpha: { value: 1 },
    },
    vertexShader: `
        void main()
        {
            gl_Position = vec4(position, 1.0);
        }
    `,
    fragmentShader: `
        uniform float uAlpha;

        void main()
        {
            gl_FragColor = vec4(0.0, 0.0, 0.0, uAlpha);
        }
    `
})

const overlay = new THREE.Mesh(overlayGeometry, overlayMaterial)
scene.add(overlay)

/**
 * Update all materials
 */
const updateAllMaterials = () =>
{
    scene.traverse((child) =>
    {
        if(child instanceof THREE.Mesh && child.material instanceof THREE.MeshStandardMaterial)
        {
            // child.material.envMap = environmentMap
            child.material.envMapIntensity = debugObject.envMapIntensity
            child.material.needsUpdate = true
            child.castShadow = true
            child.receiveShadow = true
        }
    })
}

/**
 * Environment map
 */
const environmentMap = cubeTextureLoader.load([
    '/textures/environmentMaps/6/px.png',
    '/textures/environmentMaps/6/nx.png',
    '/textures/environmentMaps/6/py.png',
    '/textures/environmentMaps/6/ny.png',
    '/textures/environmentMaps/6/pz.png',
    '/textures/environmentMaps/6/nz.png'
])
environmentMap.encoding = THREE.sRGBEncoding


const environmentMap2 = cubeTextureLoader.load([
    '/textures/environmentMaps/5/px.png',
    '/textures/environmentMaps/5/nx.png',
    '/textures/environmentMaps/5/py.png',
    '/textures/environmentMaps/5/ny.png',
    '/textures/environmentMaps/5/pz.png',
    '/textures/environmentMaps/5/nz.png'
])
environmentMap2.encoding = THREE.sRGBEncoding


scene.background = environmentMap
scene.environment = environmentMap

debugObject.envMapIntensity = 4



/**
 * Models
 */
let mixer = null
let animation = {} 
gltfLoader.load(
    '/models/Astro/astronauta.glb',
    (gltf) =>
    {
        //gltf.scene.scale.set(2, 2, 2)
        //gltf.scene.rotation.y = Math.PI * 1
        //gltf.scene.rotation.z = Math.PI * 0.5
        scene.add(gltf.scene)

        // Animation
        mixer = new THREE.AnimationMixer(gltf.scene)
        
        // Actions
        animation.actions = {}
        animation.actions.wait = mixer.clipAction(gltf.animations[3])
        animation.actions.speak = mixer.clipAction(gltf.animations[0])
        animation.actions.jump = mixer.clipAction(gltf.animations[1])
        animation.actions.dance = mixer.clipAction(gltf.animations[2])
        animation.actions.current = animation.actions.wait
        animation.actions.current.play() 

        // Play the action
        animation.play = (name) =>
        {
            const newAction = animation.actions[name]
            const oldAction = animation.actions.current

            newAction.reset()
            newAction.play()
            newAction.crossFadeFrom(oldAction, 1)

            animation.actions.current = newAction
        }

        updateAllMaterials()
    }
)

/**
 * Floor
 */
const floor = new THREE.Mesh(
    new THREE.CircleGeometry(5, 64),
    new THREE.MeshStandardMaterial({
        color: '#f7f7ff',
        metalness: 0,
        roughness: 0.5,
    })
)
floor.receiveShadow = true
floor.rotation.x = - Math.PI * 0.5
scene.add(floor)


/**
 * Lights
 */
const directionalLight = new THREE.DirectionalLight('#ffffff', 3)
directionalLight.castShadow = true
directionalLight.shadow.camera.far = 15
directionalLight.shadow.mapSize.set(1024, 1024)
directionalLight.shadow.normalBias = 0.05
directionalLight.position.set(- 1.5, 1.5, 1)
scene.add(directionalLight)


/**
 * Sizes
 */
function vh(percent) {
    var h = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
    return (percent * h) / 100;
}
const sizes = {
    width: window.innerWidth,
    height: vh(45)
}
const mediaQuery = window.matchMedia('(min-width: 1024px)')
if (mediaQuery.matches) {
    sizes.height = vh(75)
}

window.addEventListener('resize', () =>
{
    // Update sizes
    sizes.width = window.innerWidth
    if (mediaQuery.matches) {
        sizes.height = vh(75)
    } else {
        sizes.height = vh(45)
    }
    

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})

/**
 * Camera
 */
// Base camera
const camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height, 0.1, 100)
camera.position.set(0, 1.5, 2)
camera.zoom = 4
scene.add(camera)
gui.add(camera.position, 'x').min(- 5).max(5).step(0.05)
gui.add(camera.position, 'y').min(- 5).max(5).step(0.05)
gui.add(camera.position, 'z').min(- 5).max(5).step(0.05)


// Controls
const controls = new OrbitControls(camera, canvas)
controls.target.set(0, 0.75, 0)
controls.enableDamping = true
controls.enabled = false;
gui.add(controls.target, 'x').min(- 5).max(5).step(0.05)
gui.add(controls.target, 'y').min(- 5).max(5).step(0.05)
gui.add(controls.target, 'z').min(- 5).max(5).step(0.05)


/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    canvas: canvas,
    antialias: true
})
renderer.physicallyCorrectLights = true
renderer.outputEncoding = THREE.sRGBEncoding
renderer.toneMapping = THREE.ReinhardToneMapping
renderer.toneMappingExposure = 3
renderer.shadowMap.enabled = true
renderer.shadowMap.type = THREE.PCFSoftShadowMap
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

/**
 * Animate
 */
const clock = new THREE.Clock()
let previousTime = 0
const tick = () =>
{
    const elapsedTime = clock.getElapsedTime()
    const deltaTime = elapsedTime - previousTime
    previousTime = elapsedTime

    // Model animation
    if(mixer)
    {
        mixer.update(deltaTime)
    }

    // Update controls
    controls.update()

    
    // Render
    renderer.render(scene, camera)

    // Call tick again on the next frame
    window.requestAnimationFrame(tick)
}

tick()







document.addEventListener("animation", function(event) {
    //floor.material.color.setHex( event.detail.color );
    animation.play(event.detail.animation)

    scene.background = event.detail.environmentmap
    scene.environment = event.detail.environmentmap

    gsap.to(camera.position,{
        x: event.detail.camera[0],
        y: event.detail.camera[1],
        z: event.detail.camera[2],
        duration: 1.5,
        onUpdate: function() {
            camera.lookAt(0,0,0)
        }
    })
    gsap.to(controls.target,{
        x: event.detail.target[0],
        y: event.detail.target[1],
        z: event.detail.target[2],
        duration: 1.5,
    })
});

  
const button1 = document.getElementById('button1');
button1.addEventListener("click", function () {
    document.dispatchEvent(new CustomEvent("animation", { 
        detail: { animation: "speak", camera: [0,1.4,0.8], target: [0,1.2,0], environmentmap: environmentMap}
    }));
}); 

  
const button2 = document.getElementById('button2');
button2.addEventListener("click", function () {
    document.dispatchEvent(new CustomEvent("animation", {
        detail: { animation: "jump", camera: [0, 1.5, 2], target: [0,0.75,0], environmentmap: environmentMap2}
    }));
});

const button3 = document.getElementById('button3');
button3.addEventListener("click", function () {
    document.dispatchEvent(new CustomEvent("animation", {
        detail: { animation: "dance", camera: [0.5, 3, 4], target: [0,0.75,0], environmentmap: environmentMap }
    }));
});